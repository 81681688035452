import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NotificationService } from '../../services/notificationService';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
//import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  loginForm: FormGroup = new FormGroup({});
  EmailForm!: FormGroup;
  PasswordForm!: FormGroup;
  showPassword = false;
  submitted: boolean = false;
  email!: string;
  password!: string;
  errorMessage: string = '';
  isAgreed: boolean = false;
  showPopup = false;
  user: any;
  showPopup1 = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: NotificationService,
    private authService:AuthService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth,
   // public ngxLoader: NgxUiLoaderService,
    public firestore: AngularFirestore
  ) {
    this.user = this.afAuth.authState;
  }

  ngOnInit(): void {
    //console.log(window.screen.width, window.screen.height);
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
    });
   
  }
  get f() {
    return this.loginForm.controls;
  }
  login(data:any) {
    console.log(data)
    this.authService.login(data.email, data.password).then(
        res => {
            console.log('Logged in!', res);
            this.toastr.showSuccess("Successfully!!", 'login')
            this.router.navigate(['/dashboard/home']);
        },
        err => {
          this.toastr.showError("Failed!!",'login')
            console.log('Login failed', err);
        }
    );
}
}
