import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './default/login/login.component';
import { DashboardComponent } from './default/dashboard/dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {path:'dashboard',component:DashboardComponent,
    data: {
      title: "Home"
    },
  children: [
  {
    path: 'project',
    loadChildren: () =>
      import('./module/project-management/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'expenses',
    loadChildren: () =>
      import('./module/expenses/expenses.module').then((m) => m.ExpensesModule),
  },
  {
    path: 'purchase',
    loadChildren: () =>
      import('./module/purchase-entry/purchase.module').then((m) => m.PruchaseModule),
  },
  {
    path: 'supplier',
    loadChildren: () =>
      import('./module/supplier-management/supplier.module').then((m) => m.SupplierModule),
  },
  {
    path: 'supplierCategory',
    loadChildren: () =>
      import('./module/supplierCategory/supplier.module').then((m) => m.SupplierCategoryModule),
  },
  {
    path: 'site',
    loadChildren: () =>
      import('./module/site/site.module').then((m) => m.SiteModule),
  },
  {
    path: 'generalUnit',
    loadChildren: () =>
      import('./module/general-unit/genralunit.module').then((m) => m.GeneralUnitModule),
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./module/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'purchaseOrder',
    loadChildren: () =>
      import('./module/purchase-order/purchaseOrder.module').then((m) => m.PruchaseOrderModule),
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./module/inventory/inventory.module').then((m) => m.InventoryModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./module/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'expensesType',
    loadChildren: () =>
      import('./module/expenses-type/expenses-type.module').then((m) => m.ExpensesTypeModule),
  },
  {
    path: 'category',
    loadChildren: () =>
      import('./module/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'employee',
    loadChildren: () =>
      import('./module/employee/employee.module').then((m) => m.EmployeeModule),
  },
  {
    path: 'designation',
    loadChildren: () =>
      import('./module/desgination/desgination.module').then((m) => m.DesignationModule),
  },
  {
    path: 'workType',
    loadChildren: () =>
      import('./module/work-type/worktype.module').then((m) => m.workTypeModule),
  },
  {
    path: 'labour',
    loadChildren: () =>
      import('./module/labour/labour.module').then((m) => m.LabourModule),
  },
  {
    path: 'salaryConfiguration',
    loadChildren: () =>
      import('./module/salary-configuration/salary-config.module').then((m) => m.salaryConfigModule),
  },
  {
    path: 'salaryHistory',
    loadChildren: () =>
      import('./module/salary-history/salaryHistory.module').then((m) => m.SalaryHistoryModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./module/user/user.module').then((m) => m.userModule),
  },
  {
    path: 'labourAccount',
    loadChildren: () =>
      import('./module//labour-account/labourAcc.module').then((m) => m.LabourAccountModule),
  },
  {
    path: 'sales',
    loadChildren: () =>
      import('./module/sales/sales.module').then((m) => m.SalesModule),
  },
  {
    path: 'siteEngineer',
    loadChildren: () =>
      import('./module/site-engineer/siteEngg.module').then((m) => m.SiteEnggModule),
  },
  {
    path: 'journalEntry',
    loadChildren: () =>
      import('./module/journal-entry/journal-entry.module').then((m) => m.JournalModule),
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('./module/partner/partner.module').then((m) => m.PartnerModule),
  },
]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
