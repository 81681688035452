<!-- <mat-nav-list>
    <a *ngFor="let item of routes" mat-list-item [routerLinkActive]="'active-link'" [routerLink]="[item.url]">
      <mat-icon color="primary" class="sidenav-icon">{{ item.icon }}</mat-icon>
    </a>
    <mat-divider>
    </mat-divider>
</mat-nav-list>
   -->
   <mat-nav-list>
    <ng-container *ngFor="let item of routes; let i = index">
      <ng-container *ngIf="!item.subItems; else hasSubItems">
        <a mat-list-item [routerLinkActive]="'active-link'" [routerLink]="[item.url]" class="menu-item">
          <mat-icon color="primary" class="sidenav-icon">{{ item.icon }}</mat-icon>
        </a>
      </ng-container>
      <ng-template #hasSubItems>
        <a mat-list-item (click)="toggleSubmenu(i)" class="menu-item">
          <mat-icon color="primary" class="sidenav-icon">{{ item.icon }}</mat-icon>
          <mat-icon color="primary" class="sidenav-icon">{{ item.isOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
        </a>
        <mat-nav-list *ngIf="item.isOpen" class="subItems">
          <a *ngFor="let subItem of item.subItems" mat-list-item [routerLinkActive]="'active-link'" [routerLink]="[subItem.url]" class="submenu-item">
            <mat-icon color="primary" class="sidenav-icon">{{ subItem.icon }}</mat-icon>
          </a>
        </mat-nav-list>
      </ng-template>
    </ng-container>
  </mat-nav-list>
  
  