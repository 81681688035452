import { Component } from '@angular/core';
import { navItems } from '../nav';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent {
  routes=navItems;
  
  // toggleSubmenu(item: any) {
  //   item.isOpen = !item.isOpen;
  // }
  activeIndex:any=null;

  toggleSubmenu(index: number) {
    if (this.activeIndex === index) {
      this.routes[index].isOpen = !this.routes[index].isOpen;
    } else {
      if (this.activeIndex !== null) {
        this.routes[this.activeIndex].isOpen = false;
      }
      this.routes[index].isOpen = true;
      this.activeIndex = index;
    }
  }
}
