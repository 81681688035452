export const navItems = [
    {
      name: "Home",
      url: "/dashboard/home",
      icon: "home",
      isOpen:false
      
      
    },
    {
      name:"Mass Configuration",
      icon:"settings",
      subItems:[
        // {
        //   name: "General Unit",
        //   url: "/dashboard/generalUnit",
        //   icon: "dataset",
          
        // },
        {
          name: "Product Category",
          url: "/dashboard/category",
          icon: "assignment_add",
          
        },
        {
          name: "Expenses Type",
          url: "/dashboard/expensesType",
          icon: "add_chart",
          
        },
        {
          name: "General Unit",
          url: "/dashboard/generalUnit",
          icon: "dataset",
          
        },
        {
          name: "Product",
          url: "/dashboard/product",
          icon: "production_quantity_limits",
          
        },
        {
            name: "Project Management",
            url: "/dashboard/project",
            icon: "work",
            
          },
    
          {
            name: "Working Site",
            url: "/dashboard/site",
            icon: "location_city",
            
          },
          { name: "Supplier Management", url: "/dashboard/supplier", icon: "inventory" },
          // { name: "Supplier Category", url: "/dashboard/supplierCategory", icon: "category" }
      ],
      isOpen:false
    },
    {
      name: "User",
      url: "/dashboard/user",
      icon: "person_edit",
      isOpen:false
    },
    {
     name:"Employee",
     icon:"account_circle",
     subItems:[
      { name: "Employee", url: "/dashboard/employee", icon: "group" },
      { name: "Work Type", url: "/dashboard/workType", icon: "work_history" },
      {name:"Designation",url:"/dashboard/designation",icon:"person_pin"},
      {name:"Labour",url:"/dashboard/labour",icon:"group_add"},
      {name:"Salary Configuration",url:"/dashboard/salaryConfiguration",icon:"price_change"},
      {name:"Salary History",url:"/dashboard/salaryHistory",icon:"payments"},
     ],
     isOpen:false
    },
    {
      name: "Partner",
      icon: "handshake",
      subItems:[
        {
          name: "Partner",
          icon: "view_list",
          url:"/dashboard/partner"
          
        },
      ],
      isOpen:false
      
    },
   
      {
        name:"Purchase",
        icon:"shopping_cart",
        subItems:[
      {
        name: "Purchase Entry",
        url: "/dashboard/purchase",
        icon: "request_quote",
        
      },
      {
       name:"Purchase Order",
       url:"/dashboard/purchaseOrder",
       icon:"add_shopping_cart"
      },
    ],
    isOpen:false
  },
      {
        name:"Inventory",
        url:"/dashboard/inventory",
        icon:"inventory",
        isOpen:false
       },
       {
        name:"Day to Day",
        icon:"calendar_today",
        subItems:[
          {
            name: "Overall Expenses",
            url: "/dashboard/expenses",
            icon: "pie_chart",
            
          },
          {
            name: "Labour Account",
            url: "/dashboard/labourAccount",
            icon: "person_apron",
            
          },
          {
            name: "Sales",
            url: "/dashboard/sales",
            icon: "sell",
            
          },
          {
            name: "Site Enginneer",
            url: "/dashboard/siteEngineer",
            icon: "engineering",
            
          },
          {
            name: "Journal Entry",
            url: "/dashboard/journalEntry",
            icon: "description",
            
          },
        ],
        isOpen:false
       }
     
]