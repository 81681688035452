import { Component } from '@angular/core';
import { navItems } from '../nav';

@Component({
  selector: 'app-side-bar-closed',
  templateUrl: './side-bar-closed.component.html',
  styleUrl: './side-bar-closed.component.scss'
})
export class SideBarClosedComponent {
  routes=navItems;

  activeIndex:any= null;

  toggleSubmenu(index: number) {
    if (this.activeIndex === index) {
      this.routes[index].isOpen = !this.routes[index].isOpen;
    } else {
      if (this.activeIndex !== null) {
        this.routes[this.activeIndex].isOpen = false;
      }
      this.routes[index].isOpen = true;
      this.activeIndex = index;
    }
  }
}
