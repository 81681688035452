<mat-toolbar class="fix-nav" style="background-color:#BDECFF;">
    <img class="logo" src="../../../assets/images/logo.png" alt="logo" />
  <button type="button" class="btn-lg" mat-icon-button (click)="toggleSidebar()">
    <mat-icon aria-label="Side nav toggle icon" class="mat-menu">menu</mat-icon>
  </button>
  <div class="search-container">
    <input type="text" placeholder="Search..." />
    <button class="search-button" (click)="onSearch()">
     <mat-icon style="color:  #0081CA;">search</mat-icon>
    </button>
  </div>

  <!-- <div class="nav-brand">
    TechNetzz
  </div> -->
  <div class="userDetails">
    <h6 style="font-size: x-small;color:  #0081CA;"> {{ userName }}</h6>
    <h6 style="font-size: x-small;color:  #0081CA;">{{name}}</h6>
  </div>

  <div class="login-user">
    <div class="profile">
      <img
        class="avatar"
        src="../../../assets/images/avatar-13.png"
        alt=""
      />
    </div>
  </div>
  <div class="settings">
    <mat-icon (click)="toggleDropdown()">settings</mat-icon>
    <div *ngIf="dropdownOpen" class="dropdown-menu">
      <button (click)="logout()">Logout</button>
      <button (click)="profileSettings()">Profile Settings</button>
    </div> 
  </div>
    <div class="notifications">
      <mat-icon>notifications</mat-icon>
    </div>
   

</mat-toolbar>