import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  dropdownOpen = false;
  userName:any
  name="admin"
  @Output() sideNavToggled = new EventEmitter<void>();
  constructor(private readonly router: Router,private auth:AuthService) {}

  ngOnInit() {
    this.auth.getUser().subscribe((user:any)=>{
      if(user){
        console.log(user)
      this.userName=user.email
      }
    })
  }

  toggleSidebar(): void {
    this.sideNavToggled.emit();
  }

  onLoggedout() {
    localStorage.removeItem('isLoggedin');
    this.router.navigate(['/']);
  }
  onSearch(){
    
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  logout() {
    // Add your logout logic here
    console.log('Logout clicked');
    this.dropdownOpen = false;
  }

  profileSettings() {
    // Add your profile settings logic here
    console.log('Profile Settings clicked');
    this.dropdownOpen = false;
  }

}
