import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FirestoreModule } from '@angular/fire/firestore';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { HeaderComponent } from './default/header/header.component';
import { SideNavComponent } from './default/side-nav/side-nav.component';
import { SideBarClosedComponent } from './default/side-bar-closed/side-bar-closed.component';
import { ProjectModule } from './module/project-management/project.module';
import { LoginComponent } from './default/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { NotificationService } from './services/notificationService';
import { ExpensesModule } from './module/expenses/expenses.module';
import { PruchaseModule } from './module/purchase-entry/purchase.module';
import { SupplierModule } from './module/supplier-management/supplier.module';
import { DashboardComponent } from './default/dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from "ngx-bootstrap/modal";
import { SupplierCategoryModule } from './module/supplierCategory/supplier.module';
import { SiteModule } from './module/site/site.module';
import { GeneralUnitModule } from './module/general-unit/genralunit.module';
import { ProductModule } from './module/product/product.module';
import { AuthService } from './services/auth.service';
import { PruchaseOrderModule } from './module/purchase-order/purchaseOrder.module';
import { InventoryModule } from './module/inventory/inventory.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { HomeModule } from './module/home/home.module';
import { ExpensesTypeModule } from './module/expenses-type/expenses-type.module';
import { CategoryModule } from './module/category/category.module';
import { EmployeeModule } from './module/employee/employee.module';
import { DesignationModule } from './module/desgination/desgination.module';
import { workTypeModule } from './module/work-type/worktype.module';
import { LabourModule } from './module/labour/labour.module';
import { salaryConfigModule } from './module/salary-configuration/salary-config.module';
import { SalaryHistoryModule } from './module/salary-history/salaryHistory.module';
import { userModule } from './module/user/user.module';
import { JournalModule } from './module/journal-entry/journal-entry.module';
import { LabourAccountModule } from './module/labour-account/labourAcc.module';
import { SalesModule } from './module/sales/sales.module';
import { SiteEnggModule } from './module/site-engineer/siteEngg.module';
import { PartnerModule } from './module/partner/partner.module';





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideNavComponent,
    SideBarClosedComponent,
    LoginComponent,
    DashboardComponent,
    
   
  
   
   
   
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ModalModule,
    ReactiveFormsModule,
    CollapseModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
   MatTooltipModule,
   LayoutModule,
   MatToolbarModule,
   FirestoreModule,
   AngularFirestoreModule,
   AngularFireAuthModule,
   AngularFirestoreModule,
   AngularFireStorageModule,
   AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
   AngularFireAuthModule,
    ProjectModule,
    HttpClientModule,
   ToastrModule.forRoot(),
   ExpensesModule,
   PruchaseModule,
   SupplierModule,
   SupplierCategoryModule,
   SiteModule,
   GeneralUnitModule,
   ExpensesTypeModule,
   CategoryModule,
   ProductModule,
   PruchaseOrderModule,
   InventoryModule,
   MatExpansionModule,
   HomeModule,
   EmployeeModule,
   DesignationModule,
   workTypeModule,
   LabourModule,
   salaryConfigModule,
   SalaryHistoryModule,
   userModule,
   JournalModule,
   LabourAccountModule,
   SalesModule,
   SiteEnggModule,
   PartnerModule,


  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    NotificationService,
    BsDropdownDirective,
    AuthService
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
