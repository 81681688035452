<div class="layout-container font-regular" mat-app-background>
    <app-header  (sideNavToggled)="onToolbarMenuToggle()"></app-header>
    <mat-sidenav-container class="sidenav-container" >
      <mat-sidenav #snav position="start" disableClose="true" role="navigation" class="sidenav mat-elevation-z10"
        [mode]="sideNavMode" [opened]="sideNavDefaultOpened"
        [ngStyle]="{'width.px': isExpanded ? openedWidth :  closedWidth}">
        <app-side-nav *ngIf="showFullMenu; else elseBlock"></app-side-nav>
        <ng-template #elseBlock>
          <app-side-bar-closed></app-side-bar-closed>
        </ng-template>
      </mat-sidenav>
      <mat-sidenav-content role="rigion" id="sidenav-content"
        [ngStyle]="{'margin-left.px': isExpanded && !isMobile ? openedWidth :  closedWidth}">
        <main class="main-container">
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>