
<div class="body">
    <div class="container-fluid">
        <div class="main">
            <div class="login">
                <form [formGroup]="loginForm">
                    <div class="form-group1">
                        <div class="card">
                            <span class="light-logo">
                                <img src="../../../assets/images/logo.png" alt="logo"/>
                            </span>
                            <div class="head1">
                               
                            </div>
                            <div class="head2">
                              <h4>Aura Contrivers</h4>
                                <h4>Login</h4>
                            </div>
                            <div class="card-body">
                                <div>
                                    <input
                                        type="text"
                                        id="email"
                                        placeholder="Enter email"
                                        class="form-control"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                                    />
                                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                        <div *ngIf="f['email'].errors['email']">Email is required</div>
                                    </div>
                                </div>
                                <br>
                                <div class="password-input">
                                    <div>
                                        <input
                                            type="password"
                                            id="password"
                                            placeholder="Enter password"
                                            class="form-control"
                                            formControlName="password"
                                            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                                        />
                                        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                            <div *ngIf="f['password'].errors['password']">Password is required</div>
                                        </div>
                                    </div>
                                </div>
                                <button (click)="login(loginForm.value)">Login</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>

